export type SubjectBasedActionMap = {
  'archived-roles': 'access';
  'benefits-plus': 'access';
  'casting-list': 'read' | 'write' | 'manage.talent';
  'custom-url': 'write' | 'read';
  'email-representative': 'access';
  'fast-capture': 'access';
  'favorite-list': 'read';
  'form-response': 'read';
  'open-call': 'access';
  'project-breakdown': 'access';
  'project-series': 'default-check' | 'filter';
  'project-wizard': 'access';
  'publication-history': 'access';
  'publication-settings': 'none' | 'reps' | 'cb';
  'rep-shortcode': 'view';
  'representative-list': 'read';
  'role-tracker': 'access';
  'social-media': 'read' | 'write';
  'submission-trends': 'read';
  'talent-auditions-history': 'read';
  'talent-scout': 'access';
  'virtual-audition': 'write';
  'zendesk-support': 'display';
  account: 'administer';
  benefits: 'access';
  casting: 'access';
  collaborators: 'read';
  form: 'read' | 'write';
  presentation: 'share' | 'read' | 'write';
  profile: 'search' | 'curate';
  report: 'read';
  representative: 'access';
  request: 'read';
  role: 'request-approval' | 'publish' | 'write';
  schedule: 'read' | 'write' | 'manage-talent';
  session: 'read' | 'write';
  submission: 'read' | 'search' | 'submit-profile';
  subscription: 'upgrade';
  talent: 'access';
  worksheet: 'read';
  project:
    | 'read'
    | 'write'
    | 'view-last-modified'
    | 'view-owner-column'
    | 'view-status-filter'
    | 'sort-status'
    | 'view-exclusive-status';
};

export type Subject = keyof SubjectBasedActionMap;

export type Action = SubjectBasedActionMap[Subject];

export interface Rule {
  action: Action;
  subject: Subject;
}

function parseRules(rulesString: string[]): Rule[] {
  const rules: Rule[] = [];

  for (let i = 0; i < rulesString.length; i += 1) {
    const rule = rulesString[i];
    const [actionsString, subjectsString] = rule.split(':');
    const actions = actionsString.split(',') as Action[];
    const subjects = subjectsString.split(',') as Subject[];
    for (let i1 = 0; i1 < actions.length; i1 += 1) {
      const action = actions[i1];
      for (let i2 = 0; i2 < subjects.length; i2 += 1) {
        const subject = subjects[i2];
        rules.push({ action, subject });
      }
    }
  }

  return rules;
}

export class Ability {
  constructor(readonly rules: Rule[]) {}

  can<T extends Subject>(action: SubjectBasedActionMap[T], subject: T) {
    return this.rules.some((rule) => rule.action === action && rule.subject === subject);
  }

  static createFromRules(rulesString: string[]) {
    const rules = parseRules(rulesString);

    return new Ability(rules);
  }
}
